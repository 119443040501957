import React from "react"
import { Row, Col, Carousel } from "antd"

import TravelAward from "../../assets/images/Vote for RHR at World Travel Awards.png"

import "./FeaturedContents.scss"

const FeaturedContents = () => {
  return (
    <div className="featured-content-container">
      <Row className="paddingpX50">
        <Col xl={12} xs={23} className="image-featured">
          <Carousel
            autoplay
            autoplaySpeed={5000}
            className="image-featured-carousel carousel-dots-custom"
          >
            <img
              src={TravelAward}
              className="img-responsive"
              alt="Image Feature"
            />
          </Carousel>
        </Col>
        <Col xl={12} xs={21} className="featured-content-details">
          <div>
            <h2 className="title-border">
            World Travel Awards Nominee
            </h2>
            <div className="description">
              <p>
                For this year’s prestigious World Travel Awards, Robinsons Hotels and Resorts is nominated as the{" "} 
                <strong>Philippines’ Leading Hotel Group</strong>, and Grand Summit Hotels is nominated as the <strong>Philippines’ Leading City Hotel.</strong>
              </p>
              <p>
                Your support will help us achieve greater heights.<br/>
                Proceed to the link below to <strong>register and cast your vote now.</strong>
              </p>
            </div>
            <a href="https://www.worldtravelawards.com/vote" target="_blank" className="rhr-button">
              VOTE HERE
            </a>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default FeaturedContents
